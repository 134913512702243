import React, { useState, useEffect } from "react";
import { ChevronLeft, BookOpen } from "lucide-react";
import Layout from '../components/layout/Layout';

const DigitalLibrary = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [currentPart, setCurrentPart] = useState(null);
  const [accessGranted, setAccessGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Initial scroll and access check
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Force scroll to top
      window.scrollTo(0, 0);
      
      // Check access
      const hasAccess = sessionStorage?.getItem('bookAccess') === 'granted';
      setAccessGranted(hasAccess);
      setIsLoading(false);
    }
  }, []);

  // Handle scroll when access is granted
  useEffect(() => {
    if (accessGranted && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [accessGranted]);

  // Handle scroll when book is selected
  useEffect(() => {
    if (currentPart !== null && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [currentPart]);
  
  const books = [
    {
      id: 1,
      title: "Mengenal Jurusan Part 1",
      description: "Info Mata Kuliah Program Studi A-O",
      url: "https://online.anyflip.com/xoocc/bysz/index.html",
      cover: "/IMK2024.webp"
    },
    {
      id: 2, 
      title: "Mengenal Jurusan Part 2",
      description: "Info Mata Kuliah Program Studi P-Z",
      url: "https://online.anyflip.com/xoocc/mmwe/index.html",
      cover: "/IMK2024.webp"
    },
    {
      id: 3,
      title: "Kuliah untuk Semua",
      description: "Kuliah untuk Semua, Peluang dan Kesempatan",
      url: "https://online.anyflip.com/xoocc/mrhp/index.html",
      cover: "/KUS2024.webp"
    }
  ];

  const checkPassword = () => {
    if (password === 'bukuinspiratif') {
      setAccessGranted(true);
      sessionStorage?.setItem('bookAccess', 'granted');
    } else {
      alert('Password Salah!');
      setPassword('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      checkPassword();
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const selectBook = (bookId) => {
    setCurrentPart(bookId);
  };

  const closeBook = () => {
    setCurrentPart(null);
  };

  if (isLoading) {
    return <Layout><div className="min-h-screen" /></Layout>;
  }

  if (!accessGranted) {
    return (
      <Layout>
        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-400px)] bg-gray-100 py-12">
          <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-2xl font-semibold text-center mb-6">Akses Perpustakaan Digital Kabima</h2>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter Password"
            />
            <div className="flex gap-2">
              <button 
                onClick={checkPassword}
                className="w-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Enter
              </button>
              <button 
                onClick={togglePassword}
                className="w-1/2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                {showPassword ? 'Hide' : 'Show'} Password
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (currentPart) {
    return (
      <div>
        <div className="bg-white shadow-sm">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between py-2">
              <button
                onClick={closeBook}
                className="flex items-center px-4 py-2 text-blue-500 hover:text-blue-600"
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Kembali ke Rak Buku
              </button>
              <h1 className="text-xl font-semibold">
                {books.find(book => book.id === currentPart)?.title}
              </h1>
            </div>
          </div>
        </div>

        <div style={{ width: '100vw', height: '90vh', overflow: 'hidden' }}>
          <iframe
            style={{ width: '100%', height: '100%', border: 'none' }}
            src={books.find(book => book.id === currentPart)?.url}
            seamless="seamless"
            scrolling="no"
            frameBorder="0"
            allowTransparency="true"
            allowFullScreen="true"
          />
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-12">Perpustakaan Digital Kabima</h1>
        
        {/* Fixed grid layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {books.map((book) => (
            <div 
              key={book.id}
              className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-105"
              style={{ height: 'fit-content', minHeight: '600px' }}
            >
              {/* Image container with fixed dimensions */}
              <div 
                className="relative w-full overflow-hidden"
                style={{ 
                  paddingTop: '140%',
                  backgroundColor: '#f3f4f6' // Light gray placeholder
                }}
              >
                <img
                  src={book.cover}
                  alt={book.title}
                  className="absolute inset-0 w-full h-full object-cover transition-opacity duration-200"
                  loading="eager"
                  onLoad={(e) => {
                    e.target.style.opacity = "1";
                  }}
                  style={{ opacity: 0 }}
                />
              </div>
              
              {/* Content container with fixed padding */}
              <div className="p-6 flex flex-col flex-grow">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold mb-2">{book.title}</h3>
                  <p className="text-gray-600">{book.description}</p>
                </div>
                <button
                  onClick={() => selectBook(book.id)}
                  className="mt-4 flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                >
                  <BookOpen className="w-5 h-5 mr-2" />
                  Baca Buku
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default DigitalLibrary;